@font-face {
    font-family: "Tiempos-Headline";
    src: url("fonts/TiemposHeadline-Regular.woff2") format('woff2');
}

@font-face {
    font-family: "Tiempos-Text";
    src: url("fonts/TiemposText-Regular.woff2") format('woff2');
}

@font-face {
    font-family: "Inter";
    src: url("fonts/Inter-Regular.woff2") format('woff2');
}

body {
  background-color: #fff;
  font-family: 'Tiempos-Text', serif;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.page-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-template-rows: repeat(auto-fill, minmax(10, 10vh));
  grid-row-gap: 20px;
}

.page-grid-cols {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
}

@media (max-width: 800px) {
  header h1, header h6 { margin-bottom: 0px;}
}

@media (min-width: 801px) {
  header h1, header h6 { margin-bottom: 120px;}
}

@media (max-width: 800px) {
  .hide-mobile { display: none;}
}

@media (min-width: 800px) {
  .hide-desktop { display: none;}
}

header h2, .header-right h5 { margin-bottom: 40px;}

header h6 {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
}

header h6 a {
    color: #171717;
    text-decoration: none;
  }

header h6 a img {
    padding-left: 8px;
  }

header h6 a:hover img {
  animation-timing-function: ease-in-out;
  animation: bounce 1s;
  animation-iteration-count: infinite;
}

@keyframes bounce {
      0% { transform: translateY(0px)  }
      50% { transform: translateY(-4px) }
      100% { transform: translateY(0px) }
}

/* * * * HEADER MOBILE * * * */
@media (max-width: 800px) {

header {
    background-color: #fff;
    padding: 40px 10%;
}

header p {
  font-family: 'Tiempos-Headline', serif;
  font-size: 22px;
  line-height: 38px;
}

div.rule {
    margin: 45px 0 20px 0;
    height: 1px;
    width: 20px;
    background: #171717;
  }

#page-top {
  display: flex;
  flex-direction: column;
}

.header-left {}

.header-right {}

}

/* * * * HEADER DESKTOP * * * */
@media (min-width: 800px) {

header {
    height: 75vh;
    background-color: #fff;
  }

  header p {
    font-family: 'Tiempos-Headline', serif;
    font-size: 22px;
    line-height: 40px;
  }

  div.rule {
      margin: 60px 0 20px 0;
      height: 1px;
      width: 20px;
      background: #171717;
    }

#page-top {
  display: grid;
}

.header-left {
    grid-column: span 4 / 6;
    grid-row-start: 2;
    box-sizing: border-box;
  }

.header-right {
    box-sizing: border-box;
    grid-row-start: 2;
    grid-column: span 3 / 11;
  }

}


h1, h2, h5 {
  color: #171717;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
}

h3 {
  font-family: 'Tiempos-Headline', sans-serif;
  font-size: 32px;
  line-height: 40px;
}


/* * * * CASE BANNER MOBILE * * * */
@media (max-width: 800px) {

.case {
    display: flex;
    color: #fff;
    pointer-events: none;
    min-height: 300px;
  }

.case-banner-grid {
  padding: 40px 60px 0 10%;
  }

  .case-banner-button {
    margin-top: 5px;
  }

.case h5 {
      color: rgba(255, 255, 255, 0.5);
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-bottom: 14px;
    }

    .case h3 {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 14px;
        font-family: 'Tiempos-Headline', serif;
      }

}

/* * * * CASE BANNER DESKTOP * * * */
@media (min-width: 800px) {

.case {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
    grid-template-rows: repeat(9, 10vh);
    color: #fff;
    pointer-events: none;
  }

.case-banner-grid {
    grid-column: span 4 / 6;
    grid-row-start: 4;
  }

  .case-banner-button {
    margin-top: 44px;
  }

.case h5 {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 20px;
  }

.case h3 {
    padding-bottom: 24px;
    font-family: 'Tiempos-Headline', serif;
  }

}

.case p {
    color: #fff;
  }

.case-1 {
  background-image: url('img/essve-banner.jpg');
  background-size: cover;
  background-position: left center;
  background-color: #333;
}

.case-2 {
  background-image: url('img/ccs-banner.jpg');
  background-size: cover;
  background-position: left center;
  background-color: #333;
}

.case-3 {
  background-image: url('img/bolon-banner.jpg');
  background-size: cover;
  background-position: left center;
  background-color: #333;
}

.case a {
  pointer-events: all;
}

.case-banner-button {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.case-banner-button a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  padding: 40px 40px 40px 0;
}

.case-banner-button a img {
    padding-left: 8px;
    margin-top: -3px;
  }

.case-banner-button a:hover img {
    animation-timing-function: ease-in-out;
    animation: vbounce 1s;
    animation-iteration-count: infinite;
  }

  @keyframes vbounce {
        0% { transform: translateX(0px)  }
        50% { transform: translateX(5px) }
        100% { transform: translateX(0px) }
  }




footer h5 {
  padding-bottom: 32px;
}

footer ul {
  margin-top: 24px;
}

footer ul li  {
  float: left;
  padding-right: 30px;
}



/* * * * FOOTER MOBILE * * * * */
@media (max-width: 800px) {
  footer {
    background-color: #fff;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

  }

  #footer-left {
  background-color: #f6f6f6;
  padding: 80px 10% 40px 10%;

  }

  #footer-right {
    padding: 40px 10%;
  }

}

/* * * * FOOTER DESKTOP * * * * */
@media (min-width: 800px) {
  footer {
    background-color: #fff;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
    grid-template-rows: repeat(1, 100px);
  }

  #footer-left {
  grid-column: 1/8;
  grid-row: 1/7;
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 20px;
  grid-template-rows: repeat(7, 100px);
  }

  #footer-right {
    grid-column: 9/13;
    grid-row-start: 3;

  }

}



#footer-left img {
  height: 64px;
  width: auto;
  float: left;
  margin-top: -24px;
}

#footer-left-content {
  grid-column: 2/7;
  grid-row: 3/7;

}

#footer-right a {
  color: #171717;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  padding-bottom: 3px;
}

/* * * * SUB FOOTER MOBILE * * * * */
@media (max-width: 800px) {
  #sub-footer
  {
    display: none;

  }

  #sub-footer-right {
    margin: 10px 10% 60px 10%;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }
}

/* * * * SUB FOOTER DESKTOP * * * * */
@media (min-width: 800px) {
  #sub-footer
  {
    grid-column: 2/5;
    grid-row-start: 1;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }

  #sub-footer-right {
    grid-column: 9/12;
    grid-row-start: 1;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }
}

.scroll-up-link {
  cursor: pointer;
}

#sub-footer a {
    color: #171717;
    text-decoration: none;
  }

#sub-footer a img, #sub-footer span img {
    padding-left: 7px;
    margin-top: -3px;
  }

#sub-footer a:hover img, #sub-footer span:hover img  {
  animation-timing-function: ease-in-out;
  animation: bounce 1s;
  animation-iteration-count: infinite;
}

br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 60px; /* change this to whatever height you want it */
}


/* * * * * * CASE CONTENT * * * * * * */


/* * * * SPACING MOBILE * * * */
@media (max-width: 800px) {
  .small-space {
    width: 100%;
    height: 40px;
  }


  .medium-space {
    width: 100%;
    height: 80px;
  }
}

/* * * * SPACING DESKTOP * * * */
@media (min-width: 800px) {
  .small-space {
    width: 100%;
    height: 80px;
  }


  .medium-space {
    width: 100%;
    height: 160px;
  }
}



#case-header a img {
  border: 1px solid #ececec;
  border-radius: 100px;
  float: right;
  padding: 14px;

}

#case-header a img:hover {
  transition: all 0.8s;
  transform: rotate(180deg);
  transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
}
/* * * * CASE HEADER MOBILE * * * */
@media (max-width: 800px) {

    #case-header {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
    }

    #case-header-left {
      margin: 60px 0 40px 10%;
    }

    #case-header-right {
      margin: 15px 15px 0 0;
    }

.case-big-img {
      height: 100vh;
      width: 100vw;
      background-color: #333;
      background-position: center top;
      }

}

/* * * * CASE HEADER DESKTOP * * * */
@media (min-width: 800px) {

  #case-header {
    height: 300px;
    background-color: #fff;
  }

  #case-header-left {
    grid-row-start: 2;
    grid-column: span 4 / 6;
  }

  #case-header-right {
    grid-row-start: 2;
    grid-column: span 6 / 12;
  }

.case-big-img {
    height: 200vh;
    width: 100vw;
    background-color: #333;
    background-position: center top;
    }

}

#case-header h1 {
    text-transform: uppercase;
}

#case-header h2 {
  font-family: 'Tiempos-Headline', serif;
  font-size: 22px;
  line-height: 40px;
}

.case1-big-img {
    background-image: url('img/essve-big-image.jpg');
    background-size: cover;
  }

.case2-big-img {
      background-image: url('img/ccs-big-image.jpg');
      background-size: cover;
    }

.case3-big-img {
          background-image: url('img/bolon-big-image.jpg');
          background-size: cover;
        }

.case-text-section {
      margin: 0;}


/* * * * CASE TEXT MOBILE * * * * */
@media (max-width: 800px) {
  .case-text-section {
    display: flex;
    flex-direction: column;
  }

  .case-text-section p{
    margin: 0 10%;
    width: 80%;
    font-size: 14px;
    line-height: 24px;
    }

  .case-title-block  {
    margin-left: 10%;
    margin-bottom: 24px;
    }

    .case-title-block h3 {
      font-size: 32px;
    }

    .case-title-block h5 {
      font-size: 11px;
    }

    div.case-rule {
        margin: 4px 0 6px 0;
        height: 1px;
        width: 30px;
        background: #ccc;
      }

  }

/* * * * CASE TEXT DESKTOP * * * * */
@media (min-width: 800px) {

.case-text-section p{
      grid-row-start: 3;
      grid-column: 4/10;
      font-size: 20px;
      line-height: 32px;
    }

.case-title-block {
      grid-row-start: 3;
      grid-column: 2/3;
    }

    .case-title-block h3 {
      font-size: 42px;
    }

    .case-title-block h5 {
      font-size: 12px;
    }

    div.case-rule {
        margin: 16px 0 18px 0;
        height: 1px;
        width: 30px;
        background: #ccc;
      }

}

.img-text {
    text-align: center;
    margin-top: 30px;
  }

.image-light {
    background-color: #F6F6F6;
    padding: 120px 0;
    }

  .image-green {
      background-color: #3F9E36;
      padding: 120px 0;
      }
/* * * * CASE IMG MOBILE * * * * */
@media (max-width: 800px) {

  .small-image img, .wide-image img, .full-image img  {
    margin: 0;
    max-width: 100%;
    grid-column: 1/13;
  }

}

/* * * * CASE IMG Desktop * * * * */
@media (min-width: 800px) {
  .wide-image img {
    margin: 0;
    max-width: 100%;
    grid-column: 2/12;
  }

  .full-image img {
    margin: 0;
    max-width: 100%;
    grid-column: 1/13;
  }

  .small-image img {
    margin: 0;
    max-width: 100%;
    grid-column: 3/11;
  }
}

  #essve-loader img {
    margin-top: 140px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    animation: rotation 1s linear 5;
    animation-iteration-count: infinite;
  }

  #essve-loader p {
    display: block;
    padding-bottom: 140px;

  }


@keyframes rotation {
    0%      { transform: rotate(0deg); }
    50%     { transform: rotate(-180deg); }
    100%    { transform: rotate(-360deg); }
}

  #next-case {
    background-color: #f6f6f6;
    transition: all 0.2s;
  }

  #next-case:hover {
    background-color: #eee;
    cursor: pointer;
    transition: all 0.4s;
  }

  /* * * * CASE IMG MOBILE * * * * */
  @media (max-width: 800px) {

  #next-case a {
    display: block;
    padding: 60px 0;
    text-decoration: none;
  }

  #next-case div {
    grid-column: 2/12;
  }

  #next-case h3, #next-case img {
    margin-top: 20px;
  }

  #next-case h3 {
      color: #171717;
      font-size: 22px;
    }

}

  /* * * * CASE IMG Desktop * * * * */
  @media (min-width: 800px) {

  #next-case a {
    display: block;
    padding: 180px 0;
    text-decoration: none;
  }

  #next-case div {
    grid-column: 2/12;
  }

  #next-case h3, #next-case img {
    margin-top: 45px;
  }

  #next-case h3 {
      color: #171717;
    }

}
