/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}


::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}


@font-face {
    font-family: "Tiempos-Headline";
    src: url(../../static/media/TiemposHeadline-Regular.65cb1d3a.woff2) format('woff2');
}

@font-face {
    font-family: "Tiempos-Text";
    src: url(../../static/media/TiemposText-Regular.88c42afe.woff2) format('woff2');
}

@font-face {
    font-family: "Inter";
    src: url(../../static/media/Inter-Regular.47e70f6f.woff2) format('woff2');
}

body {
  background-color: #fff;
  font-family: 'Tiempos-Text', serif;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.page-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  grid-template-rows: repeat(auto-fill, minmax(10, 10vh));
  grid-row-gap: 20px;
}

.page-grid-cols {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
}

@media (max-width: 800px) {
  header h1, header h6 { margin-bottom: 0px;}
}

@media (min-width: 801px) {
  header h1, header h6 { margin-bottom: 120px;}
}

@media (max-width: 800px) {
  .hide-mobile { display: none;}
}

@media (min-width: 800px) {
  .hide-desktop { display: none;}
}

header h2, .header-right h5 { margin-bottom: 40px;}

header h6 {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
}

header h6 a {
    color: #171717;
    text-decoration: none;
  }

header h6 a img {
    padding-left: 8px;
  }

header h6 a:hover img {
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation: bounce 1s;
          animation: bounce 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
      0% { -webkit-transform: translateY(0px); transform: translateY(0px)  }
      50% { -webkit-transform: translateY(-4px); transform: translateY(-4px) }
      100% { -webkit-transform: translateY(0px); transform: translateY(0px) }
}

@keyframes bounce {
      0% { -webkit-transform: translateY(0px); transform: translateY(0px)  }
      50% { -webkit-transform: translateY(-4px); transform: translateY(-4px) }
      100% { -webkit-transform: translateY(0px); transform: translateY(0px) }
}

/* * * * HEADER MOBILE * * * */
@media (max-width: 800px) {

header {
    background-color: #fff;
    padding: 40px 10%;
}

header p {
  font-family: 'Tiempos-Headline', serif;
  font-size: 22px;
  line-height: 38px;
}

div.rule {
    margin: 45px 0 20px 0;
    height: 1px;
    width: 20px;
    background: #171717;
  }

#page-top {
  display: flex;
  flex-direction: column;
}

.header-left {}

.header-right {}

}

/* * * * HEADER DESKTOP * * * */
@media (min-width: 800px) {

header {
    height: 75vh;
    background-color: #fff;
  }

  header p {
    font-family: 'Tiempos-Headline', serif;
    font-size: 22px;
    line-height: 40px;
  }

  div.rule {
      margin: 60px 0 20px 0;
      height: 1px;
      width: 20px;
      background: #171717;
    }

#page-top {
  display: grid;
}

.header-left {
    grid-column: span 4 / 6;
    grid-row-start: 2;
    box-sizing: border-box;
  }

.header-right {
    box-sizing: border-box;
    grid-row-start: 2;
    grid-column: span 3 / 11;
  }

}


h1, h2, h5 {
  color: #171717;
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
}

h3 {
  font-family: 'Tiempos-Headline', sans-serif;
  font-size: 32px;
  line-height: 40px;
}


/* * * * CASE BANNER MOBILE * * * */
@media (max-width: 800px) {

.case {
    display: flex;
    color: #fff;
    pointer-events: none;
    min-height: 300px;
  }

.case-banner-grid {
  padding: 40px 60px 0 10%;
  }

  .case-banner-button {
    margin-top: 5px;
  }

.case h5 {
      color: rgba(255, 255, 255, 0.5);
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-bottom: 14px;
    }

    .case h3 {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 14px;
        font-family: 'Tiempos-Headline', serif;
      }

}

/* * * * CASE BANNER DESKTOP * * * */
@media (min-width: 800px) {

.case {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
    grid-template-rows: repeat(9, 10vh);
    color: #fff;
    pointer-events: none;
  }

.case-banner-grid {
    grid-column: span 4 / 6;
    grid-row-start: 4;
  }

  .case-banner-button {
    margin-top: 44px;
  }

.case h5 {
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
    padding-bottom: 20px;
  }

.case h3 {
    padding-bottom: 24px;
    font-family: 'Tiempos-Headline', serif;
  }

}

.case p {
    color: #fff;
  }

.case-1 {
  background-image: url(../../static/media/essve-banner.74284890.jpg);
  background-size: cover;
  background-position: left center;
  background-color: #333;
}

.case-2 {
  background-image: url(../../static/media/ccs-banner.c7e61722.jpg);
  background-size: cover;
  background-position: left center;
  background-color: #333;
}

.case-3 {
  background-image: url(../../static/media/bolon-banner.37610e33.jpg);
  background-size: cover;
  background-position: left center;
  background-color: #333;
}

.case a {
  pointer-events: all;
}

.case-banner-button {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.case-banner-button a {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  padding: 40px 40px 40px 0;
}

.case-banner-button a img {
    padding-left: 8px;
    margin-top: -3px;
  }

.case-banner-button a:hover img {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation: vbounce 1s;
            animation: vbounce 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes vbounce {
        0% { -webkit-transform: translateX(0px); transform: translateX(0px)  }
        50% { -webkit-transform: translateX(5px); transform: translateX(5px) }
        100% { -webkit-transform: translateX(0px); transform: translateX(0px) }
  }

  @keyframes vbounce {
        0% { -webkit-transform: translateX(0px); transform: translateX(0px)  }
        50% { -webkit-transform: translateX(5px); transform: translateX(5px) }
        100% { -webkit-transform: translateX(0px); transform: translateX(0px) }
  }




footer h5 {
  padding-bottom: 32px;
}

footer ul {
  margin-top: 24px;
}

footer ul li  {
  float: left;
  padding-right: 30px;
}



/* * * * FOOTER MOBILE * * * * */
@media (max-width: 800px) {
  footer {
    background-color: #fff;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

  }

  #footer-left {
  background-color: #f6f6f6;
  padding: 80px 10% 40px 10%;

  }

  #footer-right {
    padding: 40px 10%;
  }

}

/* * * * FOOTER DESKTOP * * * * */
@media (min-width: 800px) {
  footer {
    background-color: #fff;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
    grid-template-rows: repeat(1, 100px);
  }

  #footer-left {
  grid-column: 1/8;
  grid-row: 1/7;
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 20px;
  grid-template-rows: repeat(7, 100px);
  }

  #footer-right {
    grid-column: 9/13;
    grid-row-start: 3;

  }

}



#footer-left img {
  height: 64px;
  width: auto;
  float: left;
  margin-top: -24px;
}

#footer-left-content {
  grid-column: 2/7;
  grid-row: 3/7;

}

#footer-right a {
  color: #171717;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  padding-bottom: 3px;
}

/* * * * SUB FOOTER MOBILE * * * * */
@media (max-width: 800px) {
  #sub-footer
  {
    display: none;

  }

  #sub-footer-right {
    margin: 10px 10% 60px 10%;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }
}

/* * * * SUB FOOTER DESKTOP * * * * */
@media (min-width: 800px) {
  #sub-footer
  {
    grid-column: 2/5;
    grid-row-start: 1;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }

  #sub-footer-right {
    grid-column: 9/12;
    grid-row-start: 1;
    padding-top: 40px;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
  }
}

.scroll-up-link {
  cursor: pointer;
}

#sub-footer a {
    color: #171717;
    text-decoration: none;
  }

#sub-footer a img, #sub-footer span img {
    padding-left: 7px;
    margin-top: -3px;
  }

#sub-footer a:hover img, #sub-footer span:hover img  {
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation: bounce 1s;
          animation: bounce 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 60px; /* change this to whatever height you want it */
}


/* * * * * * CASE CONTENT * * * * * * */


/* * * * SPACING MOBILE * * * */
@media (max-width: 800px) {
  .small-space {
    width: 100%;
    height: 40px;
  }


  .medium-space {
    width: 100%;
    height: 80px;
  }
}

/* * * * SPACING DESKTOP * * * */
@media (min-width: 800px) {
  .small-space {
    width: 100%;
    height: 80px;
  }


  .medium-space {
    width: 100%;
    height: 160px;
  }
}



#case-header a img {
  border: 1px solid #ececec;
  border-radius: 100px;
  float: right;
  padding: 14px;

}

#case-header a img:hover {
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
          transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);
}
/* * * * CASE HEADER MOBILE * * * */
@media (max-width: 800px) {

    #case-header {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
    }

    #case-header-left {
      margin: 60px 0 40px 10%;
    }

    #case-header-right {
      margin: 15px 15px 0 0;
    }

.case-big-img {
      height: 100vh;
      width: 100vw;
      background-color: #333;
      background-position: center top;
      }

}

/* * * * CASE HEADER DESKTOP * * * */
@media (min-width: 800px) {

  #case-header {
    height: 300px;
    background-color: #fff;
  }

  #case-header-left {
    grid-row-start: 2;
    grid-column: span 4 / 6;
  }

  #case-header-right {
    grid-row-start: 2;
    grid-column: span 6 / 12;
  }

.case-big-img {
    height: 200vh;
    width: 100vw;
    background-color: #333;
    background-position: center top;
    }

}

#case-header h1 {
    text-transform: uppercase;
}

#case-header h2 {
  font-family: 'Tiempos-Headline', serif;
  font-size: 22px;
  line-height: 40px;
}

.case1-big-img {
    background-image: url(../../static/media/essve-big-image.caf9644a.jpg);
    background-size: cover;
  }

.case2-big-img {
      background-image: url(../../static/media/ccs-big-image.95d60335.jpg);
      background-size: cover;
    }

.case3-big-img {
          background-image: url(../../static/media/bolon-big-image.3d0ed8f6.jpg);
          background-size: cover;
        }

.case-text-section {
      margin: 0;}


/* * * * CASE TEXT MOBILE * * * * */
@media (max-width: 800px) {
  .case-text-section {
    display: flex;
    flex-direction: column;
  }

  .case-text-section p{
    margin: 0 10%;
    width: 80%;
    font-size: 14px;
    line-height: 24px;
    }

  .case-title-block  {
    margin-left: 10%;
    margin-bottom: 24px;
    }

    .case-title-block h3 {
      font-size: 32px;
    }

    .case-title-block h5 {
      font-size: 11px;
    }

    div.case-rule {
        margin: 4px 0 6px 0;
        height: 1px;
        width: 30px;
        background: #ccc;
      }

  }

/* * * * CASE TEXT DESKTOP * * * * */
@media (min-width: 800px) {

.case-text-section p{
      grid-row-start: 3;
      grid-column: 4/10;
      font-size: 20px;
      line-height: 32px;
    }

.case-title-block {
      grid-row-start: 3;
      grid-column: 2/3;
    }

    .case-title-block h3 {
      font-size: 42px;
    }

    .case-title-block h5 {
      font-size: 12px;
    }

    div.case-rule {
        margin: 16px 0 18px 0;
        height: 1px;
        width: 30px;
        background: #ccc;
      }

}

.img-text {
    text-align: center;
    margin-top: 30px;
  }

.image-light {
    background-color: #F6F6F6;
    padding: 120px 0;
    }

  .image-green {
      background-color: #3F9E36;
      padding: 120px 0;
      }
/* * * * CASE IMG MOBILE * * * * */
@media (max-width: 800px) {

  .small-image img, .wide-image img, .full-image img  {
    margin: 0;
    max-width: 100%;
    grid-column: 1/13;
  }

}

/* * * * CASE IMG Desktop * * * * */
@media (min-width: 800px) {
  .wide-image img {
    margin: 0;
    max-width: 100%;
    grid-column: 2/12;
  }

  .full-image img {
    margin: 0;
    max-width: 100%;
    grid-column: 1/13;
  }

  .small-image img {
    margin: 0;
    max-width: 100%;
    grid-column: 3/11;
  }
}

  #essve-loader img {
    margin-top: 140px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    -webkit-animation: rotation 1s linear 5;
            animation: rotation 1s linear 5;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  #essve-loader p {
    display: block;
    padding-bottom: 140px;

  }


@-webkit-keyframes rotation {
    0%      { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    50%     { -webkit-transform: rotate(-180deg); transform: rotate(-180deg); }
    100%    { -webkit-transform: rotate(-360deg); transform: rotate(-360deg); }
}


@keyframes rotation {
    0%      { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    50%     { -webkit-transform: rotate(-180deg); transform: rotate(-180deg); }
    100%    { -webkit-transform: rotate(-360deg); transform: rotate(-360deg); }
}

  #next-case {
    background-color: #f6f6f6;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  #next-case:hover {
    background-color: #eee;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }

  /* * * * CASE IMG MOBILE * * * * */
  @media (max-width: 800px) {

  #next-case a {
    display: block;
    padding: 60px 0;
    text-decoration: none;
  }

  #next-case div {
    grid-column: 2/12;
  }

  #next-case h3, #next-case img {
    margin-top: 20px;
  }

  #next-case h3 {
      color: #171717;
      font-size: 22px;
    }

}

  /* * * * CASE IMG Desktop * * * * */
  @media (min-width: 800px) {

  #next-case a {
    display: block;
    padding: 180px 0;
    text-decoration: none;
  }

  #next-case div {
    grid-column: 2/12;
  }

  #next-case h3, #next-case img {
    margin-top: 45px;
  }

  #next-case h3 {
      color: #171717;
    }

}

